@media (min-width: 576px) {
  .position-sm-fixed {
    position: fixed !important;
  }
}

@media (min-width: 768px) {
  .position-md-fixed {
    position: fixed !important;
  }
}

@media (min-width: 992px) {
  .position-lg-fixed {
    position: fixed !important;
  }
}

