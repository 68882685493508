.ces-menu {

  width: auto !important;
  aspect-ratio: 1;
  height: 35px !important;

  &.system {
    background: url('system.svg');
  }

  &.users {
    background: url('users.svg');
  }

  &.locking-devices {
    background: url('locking-devices.svg');
  }

  &.locking-media {
    background: url('locking-media.svg');
  }

  &.time-models {
    background: url('time-models.svg');
  }

  &.access-profiles {
    background: url('access-profiles.svg');
  }

  &.jobs {
    background: url('jobs.svg');
  }

  &.programming {
    background: url('programming.svg');
  }

  &.logbook {
    background: url('logbook.svg');
  }

  &.logout {
    background: url('logout.svg');
  }
}
