.spacer-xxl {
  margin-top: 12.56vh !important;
}

.spacer-xl {
  margin-top: 8.37vh !important;
}

.spacer-l {
  margin-top: 6.28vh !important;
}

.spacer-m {
  margin-top: 4.19vh !important;
}

.spacer-s {
  margin-top: 2.09vh !important;
}

.spacer-xs {
  margin-top: 1.26vh !important;
}
