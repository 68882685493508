//
// Form Wizard
//

// twitter-bs-wizard

// [~angular-archwizard/styles/archwizard.css]

.wizard-nav{
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  .wizard-list-item{
      flex-basis: 0;
      flex-grow: 1;
      text-align: center;
      .list-item{
        width: 100%;
        font-size: 14px;
        position: relative;
      }
  }


  .step-icon{
    display: inline-block;
    width: 56px;
    height: 56px;
    line-height: 54px;
    border: 1px solid rgba($primary, 0.2);
    color: $primary;
    text-align: center;
    border-radius: 50%;
    position: relative;
    z-index: 1;
    font-size: 20px;

    @media (max-width: 575.98px) {
      width: 40px;
      height: 40px;
      line-height: 38px;
    }
  }

  .step-title{
    margin-left: 6px;
  }

  .wizard-list-item{
    &:last-child{
      .list-item{
        &::after{
          display: none;
        }
      }
    }

    .list-item{
      &.done{
        .step-icon{
          background-color: $primary;
          color: $white;
        }
        .uil{
          &:before{
            content: "\e9c3";
          }
        }
      }
    }
  }

  .list-item{
    font-size: 14px;
    position: relative;
    @media (max-width: 575.98px) {
      padding: 0.5rem;
    }
    &::after{
      content: "";
      position: absolute;
      width: 75%;
      height: 2px;
      background-color: $border-color;
      left: 62%;
      top: 50%;
      transform: translateY(-50%);
      @media (max-width: 575.98px) {
        display: none;
      }
    }
    &.active{
      color: $gray-700;
      background-color: transparent;

      .step-icon{
        background-color: rgba($primary, 0.2);
        color: $primary;
        border-color: rgba($primary, 0.2);
      }
    }
  }
}


.wizard-tab{
  display: none;
}

// Dark layout
body[data-layout-mode="dark"] {

  .wizard-nav{
    .list-item::after{
      background-color: $gray-dark-300;
    }
  }
}
