/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import 'assets/ces/ces-icons';
@import 'assets/ces/style-extensions';

@import 'assets/scss/bootstrap.scss';
@import 'assets/scss/app.scss';
@import 'assets/scss/icons.scss';
@import 'assets/scss/black-design.scss';
