.cursor-pointer {
  cursor: pointer;
}

.cursor-none {
  cursor: none;
}

.pointer-events-none {
  pointer-events: none;
}

// animations
@keyframes ces-fadeInOut {
  0% {opacity: 1}
  20% {opacity: 0.3}
  100% {opacity: 1}
}

.ces-dtw-loading {
  animation: ces-fadeInOut 1s infinite ease-in-out;
}


// style classes
@import "ces-colors";

.ces-bg-secondary {
  background-color: $ces-secondary !important;
}

// design improvements
.header-item:hover {
  background: rgba($ces-secondary, 0.1) !important;
}

.menu-item {
  font-weight: 500;
  color: $ces-primary
}

.logo img {
  background: #FFFFFF;
  border-radius: 50% !important;
}

// design improvements
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
}

#sidebar-menu ul li a{
  color: $ces-primary;
}
#sidebar-menu ul li.mm-active a{
  background-color: #edf0f5;
  color: $ces-primary;
}
#sidebar-menu ul li a:hover, #sidebar-menu ul li a:hover .nav-icon {
  background-color: #f2f3fe;
  color: $ces-primary;
}

.header-item {
  color:#045da0 !important;
}
.header-item:hover {
  background-color: #edf0f5 !important;
}

// flatpickr fix
.flatpickr-input:not([readonly]) ~ input[readonly] {
  background-color: #fff !important;
}

// other extension
@import "style/position";
@import "style/border";
