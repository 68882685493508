.border-group {

  &:not(:first-child,:last-child) {
    border-radius: 0 !important;
  }

  &:first-child {
    border-radius: 0.25rem 0.25rem 0 0 !important;
   }

  &:last-child {
    border-radius: 0 0 0.25rem 0.25rem !important;
  }

  &:only-child {
    border-radius: 0.25rem !important;
  }
}

.border-group-parent > * {

  &:not(:first-child,:last-child), &:not(:first-child,:last-child) .form-control {
    border-radius: 0 !important;
  }

  &:first-child, &:first-child .form-control {
    border-radius: 0.25rem 0.25rem 0 0 !important;
  }

  &:last-child, &:last-child .form-control {
    border-radius: 0 0 0.25rem 0.25rem !important;
  }

  &:only-child, &:only-child .form-control {
    border-radius: 0.25rem !important;
  }

  // for form-controls inside child

}
