[data-bs-theme="black"] {
  .ces-locking-device {

    &.pending-changes {
      background: url('/assets/ces/locking-device/changes/icon_schliessgeraete_status_01.svg');
    }

    &.processing-changes {
      background: url('/assets/ces/locking-device/changes/icon_schliessgeraete_status_02.svg');
    }

    &.battery {

      &.low {
        background: url('/assets/ces/locking-device/battery/batterycondition-critical-black.svg') center;
        background-size: contain;
      }

      &.mid {
        background: url('/assets/ces/locking-device/battery/batterycondition-good-black.svg') center;
        background-size: contain;
      }

      &.high {
        background: url('/assets/ces/locking-device/battery/batterycondition-ok-black.svg') center;
        background-size: contain;
      }
    }
  }
}


[data-bs-theme="dark"], [data-bs-theme="light"] {
  .ces-locking-device {

    &.pending-changes {
      background: url('/assets/ces/locking-device/changes/icon_schliessgeraete_status_01.svg');
    }

    &.processing-changes {
      background: url('/assets/ces/locking-device/changes/icon_schliessgeraete_status_02.svg');
    }

    &.battery {

      &.low {
        background: url('/assets/ces/locking-device/battery/batterycondition-critical.svg') center;
        background-size: contain;
      }

      &.mid {
        background: url('/assets/ces/locking-device/battery/batterycondition-good.svg') center;
        background-size: contain;
      }

      &.high {
        background: url('/assets/ces/locking-device/battery/batterycondition-ok.svg') center;
        background-size: contain;
      }
    }
  }
}
