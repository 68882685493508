//
// TheSans - Custom Font
//


@font-face {
  font-family: 'Magistral';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../../../fonts/Magistral/magistral_book.woff")
}

@font-face {
  font-family: 'Magistral';
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src: url("../../../fonts/Magistral/magistral_book.woff")
}


@font-face {
  font-family: 'TheSans';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../../../fonts/TheSans_LT_600_.woff")
}

@font-face {
  font-family: 'TheSans';
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src: url("../../../fonts/TheSans_LT_700_.woff")
}


